/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@500;600;700&family=Lato:ital,wght@0,400;0,900;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,500&display=swap");

.grafico .modal-wrapper {
    border-radius: 0.6rem;
    --height: 75%;
    --width: 90%;
}

/*
 * Lato
 * Size: 400, 700, 900
 * FontStyle: italic
*/
ion-title {
    font-family: "Lato", sans-serif;
    font-size: 1.3em;
    letter-spacing: 2px;
    font-weight: 700;
}

/*
 * Be Vietnam Pro
 * Size: 500, 600, 700
*/
ion-label {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 1em;
}

/*
 * Roboto
 * Size: 400, 500, 700
 * FontStyle: italic
*/
ion-text,
ion-input,
ion-searchbar,
td {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
}

ion-button {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: normal;
    text-transform: none;
}

/*
 * Estilos Scss
 *
*/

.fecha {
    ion-text {
        font-size: 1em;
    }
}
